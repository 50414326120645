import showdown from 'showdown';

document.addEventListener('DOMContentLoaded', function() {
  showdown.setOption('disableForced4SpacesIndentedSublists', true);

  const converter = new showdown.Converter();

  function toMarkdown(target) {
    const text = target.value;
    var html = converter.makeHtml(text)

    var elems = document.querySelectorAll('.markdown-textarea-render');
    elems.forEach((el) => el.innerHTML = html);
  }

  function toMultiMarkdown() {
    var elems = document.querySelectorAll('.multi-markdown-textarea-render');
    elems.forEach((el) => el.innerHTML = "");

    var targets = document.querySelectorAll('.multi-markdown-textarea');

    targets.forEach((target) => {
      const text = `######${target.placeholder}\n`  + target.value;
      var html = converter.makeHtml(text)

      var elems = document.querySelectorAll('.multi-markdown-textarea-render');
      elems.forEach((el) => el.innerHTML += html);
    });
  }

  var elems = document.querySelectorAll('.markdown-textarea');
  elems.forEach(toMarkdown);
  elems.forEach((el) => el.addEventListener('input', (e) => toMarkdown(e.target)));

  var elems = document.querySelectorAll('.markdown-convert');
  elems.forEach((el) => el.innerHTML = converter.makeHtml(el.innerHTML));

  var elems = document.querySelectorAll('.multi-markdown-textarea');
  elems.forEach((el) => el.addEventListener('input', (e) => toMultiMarkdown(e.target)));

  toMultiMarkdown();
});
